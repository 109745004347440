module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/var/app/src/layouts/page.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/var/app","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FC ASSORT / FINAL FANTASY XIV","short_name":"ASSORT","lang":"ja","start_url":"/","background_color":"#fff","theme_color":"#333","display":"standalone","icon":"src/images/app-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dad7ffc74483dfa3eba5fb1ab0da153d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"requestConcurrency":2,"previewRequestConcurrency":2,"timeout":200000,"perPage":10},"development":{"nodeUpdateInterval":10000},"html":{"useGatsbyImage":false},"type":{"Page":{"exclude":true},"Comment":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"PostFormat":{"exclude":true}},"url":"https://admin.asxiv.com/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-89477913-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
